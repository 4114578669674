import { gql } from 'graphql-request'

export const TypedLotsListGql = gql`
  query getTypedWoolLots(
    $currentPage: Int!
    $pageSize: Int!
    $orderBy: ListTypedWoolLotsOrderByOptions!
    $orderByAscending: Boolean!
  ) {
    listTypedWoolLots(
      request: {
        currentPage: $currentPage
        pageSize: $pageSize
        orderByAscending: $orderByAscending
        orderBy: $orderBy
      }
    ) {
      currentPage
      totalPageCount
      totalResultCount
      results {
        stationBrand
        stationRecordNumber
        saleNumber
        season
        code
        totalBales
        internalReferenceCode
        typeCurrent
        internalComment
        lastTyped
        actions
      }
    }
  }
`
