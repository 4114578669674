import { IconBoxMultiple, IconLayoutGrid, IconScan, IconTools } from '@tabler/icons-react'
import { TopLevelLinkProps } from '@/components/shared/app-shell/main-menu/main-menu'

export const MAIN_MENU_LINKS: Array<TopLevelLinkProps> = [
  {
    label: 'Dashboard',
    href: '/',
    icon: <IconLayoutGrid />,
  },
  {
    label: 'Wool Store Tools',
    href: '/wool-store-tools',
    icon: <IconTools />,
  },
  {
    label: 'Typing',
    href: '/typing/program',
    icon: <IconScan />,
  },
  {
    label: 'Typed Lots',
    href: '/typing/typed-lots',
    icon: <IconBoxMultiple />,
  },
]
