import { useMemo } from 'react'
import { Card, Text, Skeleton, Stack, Box } from '@mantine/core'
import { WoolStoreWidgetValues } from './wool-store-widget-values'

export interface Props {
  index: number
  woolStoreWidgetValues: WoolStoreWidgetValues
  isLoading: boolean
}

export function WoolStoreWidget({ index, woolStoreWidgetValues, isLoading }: Props) {
  const backgroundColor = useMemo(() => {
    if (woolStoreWidgetValues.status) {
      switch (woolStoreWidgetValues.status) {
        case 'red':
          return 'red.2'
        case 'amber':
          return 'orange.3'
        case 'green':
          return 'green.0'
        default:
          return ''
      }
    }
    return ''
  }, [woolStoreWidgetValues.status])

  const iconColor = useMemo(() => {
    if (woolStoreWidgetValues.status) {
      switch (woolStoreWidgetValues.status) {
        case 'red':
          return 'red.8'
        case 'amber':
          return 'orange.8'
        case 'green':
          return 'green.8'
        default:
          return 'blue.5'
      }
    }
    return 'blue.5'
  }, [woolStoreWidgetValues.status])

  return (
    <Card
      data-testid={`WoolStoreWidget_Card_${index}`}
      shadow="sm"
      padding="sm"
      radius="md"
      withBorder
      h="100%"
      bg={backgroundColor}
    >
      <Stack gap="sm">
        <Box data-testid={`WoolStoreWidget_Icon_${index}`} c={iconColor} size="lg">
          {woolStoreWidgetValues.icon}
        </Box>
        <Text data-testid={`WoolStoreWidget_Name_${index}`} fw={500} c="gray.6">
          {woolStoreWidgetValues.name}
        </Text>
        {isLoading ? (
          <Skeleton data-testid={`WoolStoreWidget_Skeleton_${index}`} height={22} radius="lg" />
        ) : (
          <Text data-testid={`WoolStoreWidget_Value_${index}`}>{woolStoreWidgetValues.value}</Text>
        )}
      </Stack>
    </Card>
  )
}
