import { useEffect, useMemo, useRef, useState } from 'react'
import { Autocomplete, Loader, Skeleton, Stack, TextInput, Textarea } from '@mantine/core'
import { useDebouncedCallback } from '@mantine/hooks'
import { IconAlertTriangle } from '@tabler/icons-react'
import { EnterOrScanAlert } from '@/components/shared/enter-or-scan-alert'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import woolTypeOptions from './wool-types.json'

export type InitialTypingValues = {
  type: string
  comment: string
  actions: string
}

type UpdateState = {
  type: boolean
  comment: boolean
  actions: boolean
}

export interface TypingProgramFormProps {
  referenceNumberFromParams: string | null
  noResultsFromScan: boolean
  loadingState: boolean
  updateActions: (actions: string) => void
  updateComment: (comment: string) => void
  updateType: (type: string) => void
  updating?: UpdateState | null
  initialTypingValues?: InitialTypingValues | null
}

export function TypingProgramForm({
  referenceNumberFromParams,
  noResultsFromScan,
  loadingState,
  updateActions,
  updateComment,
  updateType,
  updating,
  initialTypingValues,
}: TypingProgramFormProps) {
  const [woolTypeErrorState, setWoolTypeErrorState] = useState<boolean>(false)
  const [woolTypeBorderColor, setWoolTypeBorderColor] = useState('') // Initial border color

  // Manage controlled state for the inputs
  const [woolType, setWoolType] = useState<string | undefined>('')
  const [internalComment, setInternalComment] = useState<string | undefined>('')
  const [actions, setActions] = useState<string | undefined>('')

  const woolData = useMemo(() => [...woolTypeOptions.fineWool, ...woolTypeOptions.strongWool], [])

  const actionsRef = useRef<HTMLTextAreaElement>(null)
  const commentRef = useRef<HTMLInputElement>(null)
  const typeRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const debounceDelay: number = 500

  const handleActionsChange = useDebouncedCallback(async (value: string) => {
    updateActions(value)
  }, debounceDelay)

  const handleCommentChange = useDebouncedCallback(async (value: string) => {
    updateComment(value)
  }, debounceDelay)

  const handleTypeChange = useDebouncedCallback(async (value: string) => {
    updateType(value)
  }, debounceDelay)

  // This useEffect block is responsible for updating the form state from the parent component
  useEffect(() => {
    if (loadingState || !initialTypingValues) {
      setWoolType('')
      setInternalComment('')
      setActions('')
      return
    }

    if (initialTypingValues?.type !== woolType) {
      setWoolType(initialTypingValues?.type ?? '')
    }
    if (initialTypingValues?.comment !== internalComment) {
      setInternalComment(initialTypingValues?.comment ?? '')
    }
    if (initialTypingValues?.actions !== actions) {
      setActions(initialTypingValues?.actions ?? '')
    }
  }, [initialTypingValues, loadingState])

  useEffect(() => {
    if (!woolType) {
      setWoolTypeErrorState(false)
      setWoolTypeBorderColor('')
      return
    }

    const woolDataType = woolData.find((wool) => wool === woolType)
    if (!woolDataType) {
      setWoolTypeErrorState(true)
      setWoolTypeBorderColor('var(--mantine-color-yellow-7)')
    } else {
      setWoolTypeErrorState(false)
      setWoolTypeBorderColor('')
    }
  }, [woolType])

  const loader = useMemo(() => <Loader size={20} />, [])
  const warning = useMemo(
    () => (
      <IconAlertTriangle
        size={20}
        color="var(--mantine-color-yellow-7)"
        aria-label="Unknown Type"
        role="img"
      />
    ),
    []
  )

  const textareaRightSectionProps = useMemo(
    () => ({
      style: { alignItems: 'flex-start', paddingTop: 'var(--input-padding-y, 0rem)' },
    }),
    []
  )

  const focusAndCursorToEnd = (input: HTMLInputElement | HTMLTextAreaElement | null) => {
    if (!input) return

    input.focus()
    input.selectionStart = input.value.length
    input.selectionEnd = input.value.length
  }

  // Handle the enter key press to move focus to the next input
  // Ignores the enter key press if the user is focused on the actions textarea
  const enterTabber = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return

    const isFocusedTypingInput = [typeRef.current, commentRef.current].includes(
      document.activeElement as HTMLInputElement
    )
    if (isFocusedTypingInput) {
      if (typeRef.current === document.activeElement) {
        setTimeout(() => focusAndCursorToEnd(commentRef.current))
        return
      }
      if (commentRef.current === document.activeElement) {
        e.preventDefault()
        focusAndCursorToEnd(actionsRef.current)
      }
    }
  }

  useEffect(() => {
    if (!loadingState && typeRef.current && typeRef.current !== document.activeElement) {
      focusAndCursorToEnd(typeRef.current)
    }

    if (formRef.current) {
      formRef.current?.removeEventListener('keydown', enterTabber)
      formRef.current?.addEventListener('keydown', enterTabber)
    }

    return () => {
      if (formRef.current) {
        formRef.current?.removeEventListener('keydown', enterTabber)
      }
    }
  }, [formRef, loadingState, typeRef])

  const getContent = () => {
    // If not yet scanned, show the welcome message
    if (!referenceNumberFromParams) {
      return (
        <EnterOrScanAlert
          title="Enter or scan a reference number"
          message="Scan the sample barcode or select the Enter Reference button."
        />
      )
    }

    //  If no results from scan, show the alert
    if (noResultsFromScan) {
      return <IconAlert level={AlertLevel.WARNING} title="No results found from scan" />
    }

    // Else, show the form
    return (
      <form ref={formRef}>
        <Stack gap="md">
          <Skeleton visible={loadingState}>
            <Autocomplete
              ref={typeRef}
              name="type"
              aria-label="Type input"
              placeholder="Type"
              disabled={loadingState}
              value={woolType}
              rightSection={
                <>
                  {updating?.type && loader}
                  {woolTypeErrorState && !updating?.type && warning}
                </>
              }
              styles={() => ({
                input: {
                  borderColor: woolTypeBorderColor,
                },
              })}
              data={woolTypeOptions.common}
              onChange={(value) => {
                setWoolType(value)
                handleTypeChange(value)
              }}
            />
          </Skeleton>
          <Skeleton visible={loadingState}>
            <TextInput
              ref={commentRef}
              name="comment"
              aria-label="Comment input"
              placeholder="Comment"
              disabled={loadingState}
              value={internalComment}
              onChange={(event) => {
                setInternalComment(event.currentTarget.value)
                handleCommentChange(event.currentTarget.value)
              }}
              rightSection={updating?.comment && loader}
            />
          </Skeleton>
          <Skeleton visible={loadingState}>
            <Textarea
              ref={actionsRef}
              name="actions"
              aria-label="Actions input"
              placeholder="Internal message requests"
              disabled={loadingState}
              rows={4}
              value={actions}
              onChange={(event) => {
                setActions(event.currentTarget.value)
                handleActionsChange(event.currentTarget.value)
              }}
              rightSection={updating?.actions && loader}
              rightSectionProps={textareaRightSectionProps}
            />
          </Skeleton>
        </Stack>
      </form>
    )
  }

  return <>{getContent()}</>
}
