import { request } from 'graphql-request'
import { SilentRequest, RedirectRequest } from '@azure/msal-browser'
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { Query, Mutation } from '@/loom-gql/graphql'
import { loginRequest } from '@/auth-config'
import { configService } from '@/services/config-service'
import { useGetAuthToken } from '@/hooks/use-get-auth-token'

type GraphProps = {
  gql: string | null
  queryParams?: object
  msalRequest?: SilentRequest | RedirectRequest
}

type GraphQueryFunction = (props: GraphProps & UseQueryOptions) => UseQueryResult<Query, Error>

type GraphMutationFunction = (
  props: GraphProps & UseMutationOptions
) => UseMutationResult<Mutation, Error>

type RestProps = {
  endpoint: string
  msalRequest?: SilentRequest | RedirectRequest
}

type QueryFunction = (props: RestProps & UseQueryOptions) => UseQueryResult<object, Error>

const buildUrl = (endpoint: string) =>
  [configService().get('API_HOST_URI') ?? '', endpoint].join('/')

/**
 * Wraps a GraphQL query in a useQuery hook that requires authentication.
 * @param queryKey A unique key for the query.
 * @param gql The GQL object to define the query.
 * @param msalRequest The MSAL request object to use for authentication, defaults to Read scope.
 */
export const useAuthenticatedGraphQuery: GraphQueryFunction = ({
  queryKey,
  gql,
  queryParams,
  msalRequest = loginRequest,
  enabled = true,
}) => {
  const accessToken = useGetAuthToken(msalRequest, queryKey)
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  // Note: Adding a retry prop will ignore defaults set in the QueryClient.
  return useQuery({
    queryKey,
    queryFn: () => request(buildUrl('api/graphql'), gql ?? '', queryParams ?? {}, headers),
    enabled: enabled && !!accessToken, // Don't send the request if the access token is not available yet
  })
}

/**
 * Wraps a GraphQL mutation in a useMutation hook that requires authentication.
 * @param queryKey A unique key for the query.
 * @param gql The GQL object to define the query.
 * @param msalRequest The MSAL request object to use for authentication, defaults to Read scope.
 */
export const useAuthenticatedGraphMutation: GraphMutationFunction = ({
  gql,
  msalRequest = loginRequest,
}) => {
  const accessToken = useGetAuthToken(msalRequest, [Date.now().toString()])
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  return useMutation({
    mutationFn: (variables: any) =>
      request(buildUrl('api/graphql'), gql ?? '', variables ?? {}, headers),
  })
}

/**
 * Wraps a REST query in a useQuery hook that requires authentication.
 * @param queryKey A unique key for the query.
 * @param endpoint The endpoint to query.
 * @param msalRequest The MSAL request object to use for authentication, defaults to Read scope.
 */
export const useAuthenticatedQuery: QueryFunction = ({
  queryKey,
  endpoint,
  msalRequest = loginRequest,
}) => {
  const accessToken = useGetAuthToken(msalRequest, queryKey)
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  })
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response: Response = await fetch(buildUrl(endpoint), { headers })
      const json = await response.json()
      return json
    },
    enabled: !!accessToken, // Don't send the request if the access token is not available yet
  })
}
