import { Grid } from '@mantine/core'
import { WoolStoreWidget } from './wool-store-widget'
import { WoolStoreWidgetValues } from './wool-store-widget-values'

export interface Props {
  woolStoreWidgetData: WoolStoreWidgetValues[]
  isLoading: boolean
}

export function WoolStoreWidgets({ woolStoreWidgetData, isLoading }: Props) {
  return (
    <Grid columns={10} data-testid="WoolStoreWidgets">
      {woolStoreWidgetData.map((widgetData, index) => (
        <Grid.Col
          span={widgetData.doubleWide ? { base: 5, sm: 4 } : { base: 5, sm: 2 }}
          key={index}
        >
          <WoolStoreWidget index={index} woolStoreWidgetValues={widgetData} isLoading={isLoading} />
        </Grid.Col>
      ))}
    </Grid>
  )
}
