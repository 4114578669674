import { useDocumentTitle } from '@mantine/hooks'
import { PropsWithChildren, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { OutletContextProps } from '@/components/shared/app-shell/app-shell'

export interface PageContainerProps {
  title: string
  collapseMenu?: boolean
}

/**
 * A wrapper component which handles updating the document title in the browser per page.
 * This behaviour is important for accessibility so ensure you use sensible titles which
 * accurately describe the page purpose or content.
 *
 * @param { string } title - Describes the main purpose or content of the page.
 */
export const PageContainer = ({
  title,
  collapseMenu,
  children,
}: PropsWithChildren<PageContainerProps>) => {
  useDocumentTitle(title)

  const outletContext: OutletContextProps = useOutletContext()

  useEffect(() => {
    collapseMenu && !!outletContext?.closeDesktopMenu && outletContext.closeDesktopMenu()
  }, [collapseMenu, outletContext?.closeDesktopMenu])

  return children
}
