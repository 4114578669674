import { useMsal } from '@azure/msal-react'
import { List, NavLink as MantineNavLink } from '@mantine/core'
import { IconLogout, IconUserCircle } from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'
import classes from './user-menu.module.css'

export const UserMenu = () => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  return (
    <List
      spacing="xs"
      listStyleType="none"
      w="100%"
      classNames={{
        root: classes.list,
        itemWrapper: classes.itemWrapper,
        itemLabel: classes.itemLabel,
      }}
      aria-label="User menu"
    >
      <List.Item>
        <MantineNavLink
          label={account?.name}
          // TODO: link to user account page
          to="#"
          leftSection={<IconUserCircle />}
          classNames={{
            root: classes.navLink,
            section: classes.iconSection,
          }}
          component={NavLink}
        />
      </List.Item>
      <List.Item>
        <MantineNavLink
          label="Logout"
          // TODO: wire up to logout action
          to="#"
          leftSection={<IconLogout />}
          classNames={{
            root: classes.navLink,
            section: classes.iconSection,
          }}
          component={NavLink}
        />
      </List.Item>
    </List>
  )
}
