import { useMemo } from 'react'
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table'
import { PaginationState, OnChangeFn } from '@tanstack/react-table'
import { ListTypedWoolLotsResponse, TypedWoolLot } from '@/loom-gql/graphql'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { formatDate } from '@/helpers/format-date'

// TODO: This is not a sustainable solution, all GraphQL response attributes
// should be optional.
type TypedWoolLotTableData = Pick<
  TypedWoolLot,
  | 'stationBrand'
  | 'stationRecordNumber'
  | 'saleNumber'
  | 'season'
  | 'code'
  | 'totalBales'
  | 'internalReferenceCode'
  | 'typeCurrent'
  | 'internalComment'
  | 'actions'
  | 'lastTyped'
>
type TypedWoolLotTableResponse = Omit<ListTypedWoolLotsResponse, 'results'> & {
  results: Array<TypedWoolLotTableData>
}

export function TypedLotsTable({
  data,
  isError,
  isFetching,
  isLoading,
  pagination,
  setPagination,
}: {
  data: TypedWoolLotTableResponse | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  pagination: PaginationState
  setPagination: OnChangeFn<PaginationState>
}) {
  const columns = useMemo<MRT_ColumnDef<TypedWoolLotTableData>[]>(
    () => [
      {
        accessorKey: 'stationBrand',
        header: 'Brand',
      },
      {
        accessorKey: 'stationRecordNumber',
        header: 'P Org',
      },
      {
        accessorKey: 'saleNumber',
        header: 'Sale',
      },
      {
        accessorKey: 'season',
        header: 'Season',
      },
      {
        accessorKey: 'code',
        header: 'Lot',
      },
      {
        accessorKey: 'totalBales',
        header: 'Bales',
      },
      {
        accessorKey: 'internalReferenceCode',
        header: 'Int Ref',
      },
      {
        accessorKey: 'typeCurrent',
        header: 'Type',
      },
      {
        accessorKey: 'internalComment',
        header: 'Comment',
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
      },
      {
        id: 'lastTyped',
        header: 'To Talman',
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableColumnFilterModes: false,
        accessorFn: (row) => (row.lastTyped == null ? null : new Date(row.lastTyped)),
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY hh:mm A'),
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data: data?.results ?? [],
    state: { isLoading, pagination, showProgressBars: isFetching, showAlertBanner: isError },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data for table',
        }
      : undefined,
    manualPagination: true,
    rowCount: data?.totalResultCount,
    pageCount: data?.totalPageCount,
    onPaginationChange: setPagination,
  })

  return <MantineReactTable table={table} />
}
