import { Stack, Collapse as MantineCollapse, Title, Group, TitleOrder } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import classes from './collapse.module.css'

type CollapseProps = {
  buttonLabel: string
  sectionHeading?: string
  sectionHeadingLevel?: TitleOrder
}

/**
 * Collapse component that provides a toggleable section with a title.
 *
 * @param {PropsWithChildren<CollapseProps>} props - The properties for the Collapse component.
 * @param {string} props.buttonLabel - The label of the collapsible section toggle button. Displays with a 'Show' or 'Hide' prefix.
 * @param {string} props.sectionHeading - Optional text to be displayed as section heading (opposite toggle button).
 * @param {TitleOrder} props.sectionHeadingLevel - Optional setting to ensure appropriate heading level can be used when heading present (note: typography styling stays the same irrespective of heading level).
 * @param {React.ReactNode} props.children - The content to be displayed within the collapsible section.
 *
 * @returns {JSX.Element} The rendered Collapse component.
 *
 * @example
 * ```tsx
 * import { Collapse } from './collapse';
 *
 * function App() {
 *   return (
 *     <Collapse sectionHeading="Name of collapsible section" buttonLabel="More Information">
 *       <p>This is the content that can be toggled.</p>
 *     </Collapse>
 *   );
 * }
 * ```
 */
export function Collapse({
  buttonLabel,
  sectionHeading,
  sectionHeadingLevel = 2,
  children,
}: PropsWithChildren<CollapseProps>) {
  const [isOpened, { toggle }] = useDisclosure(true)

  const iconClasses = clsx(classes.toggleIcon, {
    [classes.toggleIconRotated]: isOpened,
  })

  return (
    <Stack gap="md">
      <Group justify="space-between">
        {sectionHeading && (
          <Title order={sectionHeadingLevel} size="h3">
            {sectionHeading}
          </Title>
        )}
        <button type="button" className={classes.toggleButton} onClick={toggle}>
          <IconChevronDown className={iconClasses} />
          {isOpened ? 'Hide' : 'Show'} {buttonLabel}
        </button>
      </Group>
      <MantineCollapse in={isOpened}>{children}</MantineCollapse>
    </Stack>
  )
}
