import { gql } from 'graphql-request'

export const GetTypingContractsData = gql`
  query typingContractsData($referenceNumber: String!) {
    contractsData(request: { woolLotReferenceNumber: $referenceNumber }) {
      contracted
      contractName
      greasyLengthRangeMax
      greasyLengthRangeMin
      nktMin
      outstanding
      targetMicronMax
      targetMicronMin
      vmMax
    }
  }
`
