import 'url-search-params-polyfill' // IE support for URLSearchParams
import ReactDOM from 'react-dom/client'
import App from './app'
import { createMsalInstance } from './auth-config'

// Wrap our app in a Msal instance so that we allow only authenticated users to access the app
// See authConfig.ts for autologin on app load behaviour and other Entra ID config
createMsalInstance().then((msalInstance) => {
  ReactDOM.createRoot(document.getElementById('root')!).render(<App msalInstance={msalInstance} />)
})
