import { useEffect } from 'react'
import onScan from 'onscan.js'

// type ScanValidationError = {
//   message: string
//   scanCode: string
//   scanDuration?: Number
//   avgTimeByChar?: Number
//   minLength: Number
// }

type ScannerHookProps = {
  hasScanned: (scannedValue: string) => void
}

export function useScanner({ hasScanned }: ScannerHookProps) {
  // const focusedInput = () => {
  //   const focused = document.activeElement as HTMLTextAreaElement | HTMLInputElement | null
  //   return focused && ['INPUT', 'TEXTAREA'].includes(focused.tagName) ? focused : null
  // }

  // const insertTextAtCursor = (textInput: HTMLTextAreaElement | HTMLInputElement, text: string) => {
  //   const start = textInput.selectionStart ?? text.length
  //   const end = textInput.selectionEnd ?? text.length

  //   textInput.value = textInput.value.slice(0, start) + text + textInput.value.slice(end)
  //   const newCursorPosition = start + text.length
  //   textInput.selectionStart = newCursorPosition
  //   textInput.selectionEnd = newCursorPosition
  //   textInput.dispatchEvent(
  //     new InputEvent('input', { bubbles: true, data: text, inputType: 'insertText' })
  //   )
  // }

  const attachScan = () => {
    if (!onScan.isAttachedTo(document)) {
      // let isCtrlCombo = false
      let scanEndedInSuffix = false

      onScan.attachTo(document, {
        onScan: (sCode: string) => {
          if (scanEndedInSuffix) {
            hasScanned(sCode)
          }
          scanEndedInSuffix = false
        },
        onKeyDetect(keyCode: number) {
          // This callback is used to determine if the scan has ended in a suffix key
          // It enforces that the scan must end in the suffix key to be valid and emitted
          const scanInProgress = onScan.isScanInProgressFor(document)
          const { suffixKeyCodes } = onScan.getOptions(document)
          const isSuffixKey = (suffixKeyCodes ?? []).includes(keyCode)
          if (scanInProgress && isSuffixKey) {
            scanEndedInSuffix = true
          }
        },
        /**
         * The following callbacks were used to capture key events and update the focused input IF
         * the scan was invalid. This was to provide the ability to capture scan events behind the scenes
         * and prevent them from being emitted into the focused input.
         *
         * This behaviour has been disabled for now, due to updating the input value directly causing
         * issues with the onChange event not firing on React input elements.
         */
        // onKeyProcess(char, event) {
        //   // This callback has a `char` value for key events that are in the range
        //   // of valid scan characters. We capture the event so that any focused
        //   // input does not receive the character.

        //   // If the user is doing a ctrl-c/v/a etc, we don't want to capture the event
        //   // Store the state as a flag to check in onScanError
        //   isCtrlCombo = event.ctrlKey
        //   const blockKeyStrokesFromFocusedInput = focusedInput() && char !== '' && !isCtrlCombo
        //   if (blockKeyStrokesFromFocusedInput) {
        //     event.preventDefault()
        //   }
        // },
        // onScanError: (e: ScanValidationError) => {
        //   scanEndedInSuffix = false
        //   // Don't handle the error if there is no scan code
        //   if (e.scanCode === '') {
        //     return
        //   }
        //   // If the character(s) fail the scan validation, we can update any
        //   // focused input with the characters we captured in onKeyProcess
        //   const focused = focusedInput()
        //   const updateFocusedInputWithFailedScanChars = focused && !isCtrlCombo
        //   if (updateFocusedInputWithFailedScanChars) {
        //     insertTextAtCursor(focused, e.scanCode)
        //   }
        // },
        // The following options are used to determine if a scan is valid
        // Need to keep these pretty tight to avoid the events that onKeyPress doesn't
        // block from being emitted before the scanError handler.
        // E.g. type a character then hit space, the space could be emitted before the character.
        timeBeforeScanTest: 50,
        avgTimeByChar: 40,
        minLength: 5,
        // Enter key is the defined suffix for a scan
        suffixKeyCodes: [13],
      })
    }
  }

  const detachScan = () => {
    if (onScan.isAttachedTo(document)) {
      const vars = (document as any)?.scannerDetectionData?.vars
      if (vars?.testTimer) {
        clearTimeout(vars.testTimer)
      }
      if (vars?.longPressTimer) {
        clearTimeout(vars.longPressTimer)
      }
      onScan.detachFrom(document)
    }
  }

  useEffect(() => {
    // Attach the scanner to the document
    attachScan()

    return () => {
      // Detach the scanner from the document on unload
      detachScan()
    }
  }, [])

  return { detachScan, attachScan }
}
