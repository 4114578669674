import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { IconBoxSeam } from '@tabler/icons-react'
import { Pill, Group, Stack, Text, Anchor } from '@mantine/core'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { WoolLotDataProps } from '@/components/typing/program/typing-program-types'
import { Value } from '@/components/shared/value'
import { formatDate } from '@/helpers/format-date'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { Collapse } from '@/components/shared/collapse/collapse'
import { PillAlert } from '@/components/shared/pill-alert'
import { WoolStoreStatus } from '@/loom-gql/graphql'

export interface PreviousLotsTableProps {
  heading?: string
  data?: Array<WoolLotDataProps>
  noDataMessage?: string
  isLoading?: boolean
}

export const PreviousLotsTable = ({
  heading,
  data = [],
  noDataMessage = 'No previous lots found within the last 18 months',
  isLoading,
}: PreviousLotsTableProps) => {
  const columns = useMemo<MRT_ColumnDef<WoolLotDataProps>[]>(
    () => [
      {
        accessorKey: 'woolLotCommissionPaymentDate',
        header: 'Lotted',
        enableSorting: true,
        sortingFn: 'datetime',
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YY'),
      },
      {
        accessorKey: 'woolLotReferenceNumber',
        header: 'Ref No',
        enableSorting: false,
        Cell: ({ row }) => (
          <Stack align="flex-start">
            <Anchor
              component={Link}
              to={`/typing/program?reference=${row.original.woolLotReferenceNumber}`}
              size="sm"
            >
              {row.original.woolLotReferenceNumber}
            </Anchor>
            {row.original.coreTestBoxLocation && (
              <Pill
                bg="gray.2"
                fw={600}
                aria-label={`Box location ${row.original.coreTestBoxLocation}`}
              >
                <Group wrap="nowrap" gap="xxs">
                  <IconBoxSeam size={14} stroke={2} />
                  {row.original.coreTestBoxLocation}
                </Group>
              </Pill>
            )}
          </Stack>
        ),
      },
      {
        accessorKey: 'baleHeaderPurchaseDescription',
        header: 'Desc/Type',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div>{row.original.baleHeaderPurchaseDescription}</div>
            <div>{row.original.woolLotTypeCurrent}</div>
            <div>{row.original.woolLotInternalComment}</div>
          </>
        ),
      },
      {
        accessorKey: 'purchaseAccountName',
        header: 'Purchase Account',
        enableSorting: false,
        Cell: ({ row }) => (
          <Stack align="flex-start">
            <div>{row.original.purchaseAccountName}</div>
            {row.original.woolLotSoldStatus === WoolStoreStatus.SoldOrBin && (
              <Pill bg="gray.2" fw={600} aria-label="Sold">
                Sold
              </Pill>
            )}
          </Stack>
        ),
      },
      {
        accessorKey: 'coreTestMicronLaserscan',
        header: 'Micron',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.coreTestMicronLaserscan} unit="LS" size="sm" />
            <Value value={row.original.coreTestMicronOfda} unit="OF" size="sm" />
            <Value value={row.original.coreTestMicronAirflow} unit="AF" size="sm" />
          </>
        ),
      },
      {
        accessorKey: 'coreTestColourYZ',
        header: 'Y-Z',
        enableSorting: false,
      },
      {
        accessorKey: 'coreTestCoefficientOfVariationLaserscan',
        header: 'Mic CV',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value
              value={row.original.coreTestCoefficientOfVariationLaserscan}
              unit="CV"
              size="sm"
            />
            <Value value={row.original.coreTestCurvatureMeanLaser} unit="CUR" size="sm" />
          </>
        ),
      },
      {
        accessorKey: 'coreTestVegetableMatterBase',
        header: 'Yield',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.coreTestVegetableMatterBase} unit="VM" size="sm" />
            <Value value={row.original.coreTestYield1} size="sm" />
          </>
        ),
      },
      {
        accessorKey: 'coreTestStapleStrength',
        header: 'SS',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.coreTestStapleStrength} unit="NKT" size="sm" />
            <Value value={row.original.coreTestStapleAverageStrength} unit="SS25" size="sm" />
          </>
        ),
      },
      {
        accessorKey: 'coreTestStapleLengthGreasy',
        header: 'SL',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.coreTestStapleLengthGreasy} unit="MM" size="sm" />
            <Value
              value={row.original.coreTestStapleCoefficientOfLengthVariation}
              unit="CV%"
              size="sm"
            />
          </>
        ),
      },
      {
        accessorKey: 'coreTestHauteurLength',
        header: 'TH',
        enableSorting: false,
      },
      {
        accessorKey: 'coreTestPointOfBreakTip',
        header: 'POB',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value
              value={row.original.coreTestPointOfBreakTip}
              unit="T"
              isUnitTrailing={false}
              size="sm"
            />
            <Value
              value={row.original.coreTestPointOfBreakMid}
              unit="M"
              isUnitTrailing={false}
              size="sm"
            />
            <Value
              value={row.original.coreTestPointOfBreakBase}
              unit="B"
              isUnitTrailing={false}
              size="sm"
            />
          </>
        ),
      },
      {
        accessorKey: 'woolLotUserText3',
        header: 'Sale',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.woolLotUserText3} unit="LINE" size="sm" />
            <Value value={row.original.woolLotCode} unit="LOT" size="sm" />
            <Value value={row.original.woolLotSaleAndSeason} size="sm" />
          </>
        ),
      },
      {
        accessorKey: 'woolLotBales',
        header: 'BLS',
        enableSorting: false,
      },
      {
        accessorKey: 'qualityScheme',
        header: 'Cert',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Text size="sm" c={row.original.qualitySchemeWarning ? 'red.9' : ''}>
              {row.original.qualityScheme}
            </Text>
            {row.original.qualitySchemeWarning && <PillAlert text="Cert" />}
          </>
        ),
      },
      {
        accessorKey: 'woolLotsTotalKilosNet',
        header: 'Total',
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <Value value={row.original.woolLotsTotalKilosNet} unit="NET" size="sm" />
            <Value value={row.original.woolLotsTotalKilosClean} unit="CLN" size="sm" />
          </>
        ),
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: {
      showSkeletons: isLoading,
    },
    enableSorting: true,
    initialState: {
      sorting: [
        {
          id: 'woolLotCommissionPaymentDate', // Default sort by date
          desc: true,
        },
      ],
    },
    enablePagination: false,
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableBodyRowProps: ({ row }) => {
      const isSold = row.original.woolLotSoldStatus === WoolStoreStatus.SoldOrBin
      return isSold ? { bg: 'gray.1', c: 'gray.5' } : {}
    },
  })
  return (
    <Collapse sectionHeading={heading} buttonLabel="Previous Lots">
      <MantineReactTable table={table} />
    </Collapse>
  )
}
