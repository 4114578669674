import { useEffect, useState } from 'react'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { QueryKey } from '@tanstack/react-query'

/**
 * Internal hook to encapsulate the logic for getting an access token.
 * @param msalRequest
 * @returns
 */
export const useGetAuthToken = (
  msalRequest: SilentRequest | RedirectRequest,
  queryKey: QueryKey
) => {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      msalRequest.account = instance.getActiveAccount() ?? undefined
      instance
        .acquireTokenSilent(msalRequest)
        .then((response) => {
          setAccessToken(response.accessToken)
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            instance.acquireTokenRedirect(msalRequest)
          }
        })
    }
  }, [inProgress, isAuthenticated, queryKey])

  return accessToken
}
