import { gql } from 'graphql-request'

export const UpdateTypingActionsGql = gql`
  mutation updateTypingActions($woolLotId: UUID!, $actions: String) {
    setTypingActions(request: { woolLotId: $woolLotId, actions: $actions }) {
      errorMessage
      success
    }
  }
`
