import dayjs from 'dayjs'

/**
 * Formats a given date string into a specified format.
 *
 * @param date - The date string to format. Can be `string`, `undefined`, or `null`.
 * @param format - The format string to apply to the date.
 * @returns The formatted date string, or an empty string if the input date is `undefined` or `null`.
 */
export function formatDate(date: Date | undefined | null, format: string) {
  if (!date) return ''
  return dayjs(date).format(format)
}
