import { gql } from 'graphql-request'

export const SetTypingInternalCommentGql = gql`
  mutation setTypingInternalComment($woolLotId: UUID!, $internalComment: String) {
    setTypingInternalComment(
      request: { woolLotId: $woolLotId, internalComment: $internalComment }
    ) {
      errorMessage
      success
    }
  }
`
