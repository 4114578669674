import { Link } from 'react-router-dom'
import classes from './logo.module.css'
import NZMHubLogo from '@/assets/nzm-hub-logo.svg'

export interface LogoProps {
  logoImageUrl?: string
  logoImageAltText?: string
}

export const Logo = ({
  logoImageUrl = NZMHubLogo,
  logoImageAltText = 'New Zealand Merino Hub',
}: LogoProps) => (
  <Link className={classes.logo} to="/">
    <img className={classes.logoImage} src={logoImageUrl} alt={logoImageAltText} />
  </Link>
)
