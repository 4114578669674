import { Skeleton, Stack, Title, Text, VisuallyHidden, rem } from '@mantine/core'
import { useOutletContext } from 'react-router-dom'
import classes from './typing-program-header.module.css'
import { OutletContextProps } from '@/components/shared/app-shell/app-shell'

type TypingProgramHeaderProps = {
  woolLotReferenceNumber: string | undefined | null
  baleHeaderPurchaseBrand: string | undefined | null
  baleHeaderPurchaseDescription: string | undefined | null
  loadingState: boolean
}

export function TypingProgramHeader({
  woolLotReferenceNumber,
  baleHeaderPurchaseBrand,
  baleHeaderPurchaseDescription,
  loadingState,
}: TypingProgramHeaderProps) {
  const outletContext: OutletContextProps = useOutletContext()
  const isWideContainer = outletContext?.isWideContainer

  return (
    <Stack gap="xs" w="100%">
      {loadingState ? (
        <Skeleton visible={loadingState} height={34} mt={8} width="60%" />
      ) : (
        <Title order={1} size={isWideContainer ? rem(28) : rem(26)} className={classes.refHeading}>
          {woolLotReferenceNumber || 'Typing'}
        </Title>
      )}
      <div>
        {loadingState ? (
          <>
            <Skeleton visible={loadingState} height={22} mt={8} width="70%" />
            <Skeleton visible={loadingState} height={22} mt={8} width="50%" />
          </>
        ) : (
          <>
            <Text size={isWideContainer ? 'xl' : 'lg'} fw={600} lh={1}>
              <VisuallyHidden>Purchase brand</VisuallyHidden>
              {baleHeaderPurchaseBrand}
            </Text>
            <Text size={isWideContainer ? 'xl' : 'lg'} fw={600}>
              <VisuallyHidden>Purchase description</VisuallyHidden>
              {baleHeaderPurchaseDescription}
            </Text>
          </>
        )}
      </div>
    </Stack>
  )
}
