import { TextInput, TextInputProps } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import classes from './search.module.css'

// TODO: wire up search functionality
export const Search = (props: TextInputProps) => (
  <TextInput
    aria-label="Search"
    type="search"
    size="sm"
    leftSectionPointerEvents="none"
    leftSection={<IconSearch className={classes.searchIcon} />}
    classNames={{
      root: classes.input,
    }}
    {...props}
  />
)
