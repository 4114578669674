import { gql } from 'graphql-request'

export const UpdateBoxLocationGql = gql`
  mutation updateBoxLocation($coreTestId: UUID!, $boxLocation: String!) {
    setBoxLocation(request: { coreTestId: $coreTestId, boxLocation: $boxLocation }) {
      errorMessage
      success
    }
  }
`
