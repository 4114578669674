import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { Grid, Flex, Stack } from '@mantine/core'
import { useQueryClient } from '@tanstack/react-query'
import { TypingProgramDetails } from '@/components/typing/program/typing-program-details/typing-program-details'
import { WoolLotLookup } from '@/components/shared/wool-lot-lookup/wool-lot-lookup'
import { UpdateBoxLocationGql } from '@/graphql/mutations/update-box-location.graphql'
import { GetTypingProgramData } from '@/graphql/queries/typing-program-data.graphql'
import {
  useAuthenticatedGraphQuery,
  useAuthenticatedGraphMutation,
} from '@/hooks/use-authenticated-query'
import { TypingProgramHeader } from '@/components/typing/program/typing-program-header/typing-program-header'
import {
  InitialTypingValues,
  TypingProgramForm,
} from '@/components/typing/program/typing-program-form'
import { processTypingData } from '@/helpers/typing/program/typing-program-data'
import { UpdateTypingActionsGql } from '@/graphql/mutations/update-typing-actions.graphql'
import { PAGE_TITLES } from '@/constants/page-titles'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { outMessage } from '@/pages/constants'
import { SetTypingTypeCurrentGql } from '@/graphql/mutations/set-typing-type-current.graphql'
import { SetTypingInternalCommentGql } from '@/graphql/mutations/set-typing-internal-comment.graphql'
import { OutletContextProps } from '@/components/shared/app-shell/app-shell'
import { showErrorNotification } from '@/helpers/notifications/notifications'
import { GetTypingContractsData } from '@/graphql/queries/typing-contracts-data.graphql'
import { GetTypingPreviousLotsData } from '@/graphql/queries/typing-previous-lots-data.graphql'
import { ContractsTable } from '@/components/typing/program/contracts-table/contracts-table'
import { PreviousLotsTable } from '@/components/typing/program/previous-lots-table/previous-lots-table'
import { WoolLotDataProps } from '@/components/typing/program/typing-program-types'
// import { PreviousLotsTable } from '@/components/typing/program/previous-lots-table/previous-lots-table'

// Define the nested interface for each property

export function TypingProgram() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const referenceNumberFromParams = queryParams.get('reference')
  const [noResultsFromScan, setNoResultsFromScan] = useState<boolean>(false)
  const [lastReferenceNumber, setLastReferenceNumber] = useState<string | null>(null)
  const [initialTypingValues, setInitialTypingValues] = useState<InitialTypingValues | null>(null)
  const queryClient = useQueryClient()

  function handleReferenceNumberUpdate(newReferenceNumber: string | null) {
    // update query parameters
    if (newReferenceNumber === null) {
      if (referenceNumberFromParams) {
        queryParams.set('reference', referenceNumberFromParams)
      } else {
        queryParams.delete('reference')
      }
    } else {
      queryParams.set('reference', newReferenceNumber)
      if (
        newReferenceNumber === referenceNumberFromParams ||
        newReferenceNumber === lastReferenceNumber
      ) {
        refetchMainLine()
        refetchContracts()
        refetchPreviousLots()
        return
      }
    }

    setLastReferenceNumber(newReferenceNumber)
    // create new search string and navigate to it
    const newSearch = `?${queryParams.toString()}`
    navigate({ search: newSearch })
  }

  // Get the typing program data
  const {
    data: typingData,
    isLoading: typingDataLoading,
    isFetching: typingDataFetching,
    refetch: refetchMainLine,
  } = useAuthenticatedGraphQuery({
    queryKey: ['typingProgramData', referenceNumberFromParams],
    gql: GetTypingProgramData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
  })

  const typingDataLoadingOrFetching = typingDataLoading || typingDataFetching

  // Get the typing contracts data
  const {
    data: contractsData,
    isLoading: contractsDataLoading,
    isFetching: contractsDataFetching,
    refetch: refetchContracts,
  } = useAuthenticatedGraphQuery({
    queryKey: ['contractsData', referenceNumberFromParams],
    gql: GetTypingContractsData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
  })

  const contractsDataLoadingOrFetching = contractsDataLoading || contractsDataFetching

  // Get the previous lots data
  const {
    data: previousLotsData,
    isLoading: previousLotsDataLoading,
    isFetching: previousLotsDataFetching,
    refetch: refetchPreviousLots,
  } = useAuthenticatedGraphQuery({
    queryKey: ['previousLotsData', referenceNumberFromParams],
    gql: GetTypingPreviousLotsData,
    queryParams: { referenceNumber: referenceNumberFromParams },
    enabled: !!referenceNumberFromParams,
  })

  const previousLotsDataLoadingOrFetching = previousLotsDataLoading || previousLotsDataFetching

  // Pass the initial typing values to the form component anytime the "main line" changes
  useEffect(() => {
    setInitialTypingValues({
      actions: typingData?.typingProgramData?.woolLotActions ?? '',
      comment: typingData?.typingProgramData?.woolLotInternalComment ?? '',
      type: typingData?.typingProgramData?.woolLotTypeCurrent ?? '',
    })
  }, [typingData?.typingProgramData?.woolLotId, typingDataLoadingOrFetching])

  /** BOX LOCATION UPDATE BEGINS **/
  const blUpdate = useAuthenticatedGraphMutation({
    gql: UpdateBoxLocationGql,
  })

  const [oldBoxLocation, setOldBoxLocation] = useState<string | null>(null)
  function setBoxLocation(newBoxLocationInput: string | null) {
    let boxLocation: string | null = newBoxLocationInput
    // This is to support scanning special "OUT" bar-codes which should result in clearing the box location field
    if (newBoxLocationInput?.includes(outMessage)) {
      boxLocation = ''
    }
    // Store old state for rollback
    setOldBoxLocation(typingData?.typingProgramData?.coreTestBoxLocation ?? null)
    // Trigger mutation
    blUpdate.mutate({ coreTestId: typingData?.typingProgramData?.coreTestId, boxLocation })
    // To avoid refetching, we need to assume mutation will be successful and override current typing program data
    queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
      typingProgramData: {
        ...typingData?.typingProgramData,
        coreTestBoxLocation: boxLocation,
      },
    })
  }

  // Still having to make a decision about whether the box location update was successful based on the mutation response...
  // server should probably be throwing a 422 error if the update isn't successful rather than 200 OK with a success flag and possible error message
  useEffect(() => {
    const isFailedUpdate =
      blUpdate.isError || (blUpdate.isSuccess && !blUpdate.data?.setBoxLocation?.success)
    if (isFailedUpdate) {
      // Rollback to old box location
      queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
        typingProgramData: {
          ...typingData?.typingProgramData,
          coreTestBoxLocation: oldBoxLocation,
        },
      })
      setOldBoxLocation(null)
      showErrorNotification(
        `Error saving box location for Wool Lot ${typingData?.typingProgramData?.woolLotReferenceNumber}`
      )
    }
  }, [blUpdate.data, blUpdate.isSuccess, blUpdate.isError])
  /** BOX LOCATION UPDATE ENDS **/

  const [oldTypingType, setOldTypingType] = useState<string | null>(null)
  const [oldTypingComment, setOldTypingComment] = useState<string | null>(null)
  const [oldTypingActions, setOldTypingActions] = useState<string | null>(null)

  // Update the typing actions
  const actionsUpdate = useAuthenticatedGraphMutation({ gql: UpdateTypingActionsGql })

  const mutateActions = (actions: string) => {
    setOldTypingActions(typingData?.typingProgramData?.woolLotActions ?? null)
    actionsUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, actions })
    queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
      typingProgramData: {
        ...typingData?.typingProgramData,
        woolLotActions: actions,
      },
    })
  }

  useEffect(() => {
    const isFailedUpdate =
      actionsUpdate.isError ||
      (actionsUpdate.isSuccess && !actionsUpdate.data?.setTypingActions?.success)
    if (isFailedUpdate) {
      // Rollback UI to old actions
      queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
        typingProgramData: {
          ...typingData?.typingProgramData,
          woolLotActions: oldTypingActions,
        },
      })
      setOldTypingActions(null)
      showErrorNotification(
        `Error saving actions for Wool Lot ${typingData?.typingProgramData?.woolLotReferenceNumber}`
      )
    }
  }, [actionsUpdate.data, actionsUpdate.isSuccess, actionsUpdate.isError])

  // Update the typing type current
  const typeUpdate = useAuthenticatedGraphMutation({ gql: SetTypingTypeCurrentGql })

  const mutateType = (typeCurrent: string) => {
    setOldTypingType(typingData?.typingProgramData?.woolLotTypeCurrent ?? null)
    typeUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, typeCurrent })
    queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
      typingProgramData: {
        ...typingData?.typingProgramData,
        woolLotTypeCurrent: typeCurrent,
      },
    })
  }

  useEffect(() => {
    const isFailedUpdate =
      typeUpdate.isError ||
      (typeUpdate.isSuccess && !typeUpdate.data?.setTypingTypeCurrent?.success)
    if (isFailedUpdate) {
      // Rollback UI to old type
      queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
        typingProgramData: {
          ...typingData?.typingProgramData,
          woolLotTypeCurrent: oldTypingType,
        },
      })
      setOldTypingType(null)
      showErrorNotification(
        `Error saving type for Wool Lot ${typingData?.typingProgramData?.woolLotReferenceNumber}`
      )
    }
  }, [typeUpdate.data, typeUpdate.isSuccess, typeUpdate.isError])

  // Update the typing internal comment
  const commentUpdate = useAuthenticatedGraphMutation({ gql: SetTypingInternalCommentGql })

  const mutateComment = (internalComment: string) => {
    setOldTypingComment(typingData?.typingProgramData?.woolLotInternalComment ?? null)
    commentUpdate.mutate({ woolLotId: typingData?.typingProgramData?.woolLotId, internalComment })
    queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
      typingProgramData: {
        ...typingData?.typingProgramData,
        woolLotInternalComment: internalComment,
      },
    })
  }

  useEffect(() => {
    const isFailedUpdate =
      commentUpdate.isError ||
      (commentUpdate.isSuccess && !commentUpdate.data?.setTypingInternalComment?.success)
    if (isFailedUpdate) {
      // Rollback UI to old internal comment
      queryClient.setQueryData(['typingProgramData', referenceNumberFromParams], {
        typingProgramData: {
          ...typingData?.typingProgramData,
          woolLotInternalComment: oldTypingComment,
        },
      })
      setOldTypingComment(null)
      showErrorNotification(
        `Error saving comment for Wool Lot ${typingData?.typingProgramData?.woolLotReferenceNumber}`
      )
    }
  }, [commentUpdate.data, commentUpdate.isSuccess, commentUpdate.isError])

  // Process typing data for displaying
  const typingProgramDisplayData = processTypingData(typingData?.typingProgramData)
  const typingPreviousLotsDisplayData = (): WoolLotDataProps[] => {
    const results = previousLotsData?.listPreviousWoolLots?.results ?? []
    return results.map(processTypingData).filter((data) => !!data)
  }

  // Prevent navigating away from the page if there are unsaved changes
  // Not using a Mantine form for this page to track the form (values, dirty state, etc)
  // as the form has specific interactions with the scanner that need to be handled.
  const [unSavedChanges, setUnSavedChanges] = useState<boolean>(false)
  useEffect(() => {
    setUnSavedChanges(actionsUpdate.isPending || commentUpdate.isPending || typeUpdate.isPending)
  }, [commentUpdate.isPending, typeUpdate.isPending, actionsUpdate.isPending])

  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (unSavedChanges) {
        event.preventDefault()
        return true
      }
      return null // Allow navigation if no conditions met
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [unSavedChanges])

  /**
   * Adapt column widths based on container width (affected by menu
   * collapsed/expanded state on narrower viewports)
   */
  const outletContext: OutletContextProps = useOutletContext()
  const isWideContainer = outletContext?.isWideContainer

  const showTables = lastReferenceNumber || referenceNumberFromParams

  // Construct heading for Previous Lots table based on available data
  const stationRecordNumber = typingData?.typingProgramData?.stationRecordNumber || ''
  const purchaseBrand = typingData?.typingProgramData?.baleHeaderPurchaseBrand || ''
  const previousLotsHeading = `Previous Lots ${stationRecordNumber} ${purchaseBrand}`

  return (
    <PageContainer title={PAGE_TITLES.TYPING_PROGRAM} collapseMenu>
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, xl: isWideContainer ? 3 : 4 }}>
          <Flex
            direction={{ base: 'column-reverse', xl: 'row' }}
            justify="space-between"
            align="flex-start"
            gap="md"
            mb="xl"
          >
            <TypingProgramHeader
              woolLotReferenceNumber={typingProgramDisplayData?.woolLotReferenceNumber}
              baleHeaderPurchaseBrand={typingProgramDisplayData?.baleHeaderPurchaseBrand}
              baleHeaderPurchaseDescription={
                typingProgramDisplayData?.baleHeaderPurchaseDescription
              }
              loadingState={typingDataLoading}
            />
            <WoolLotLookup
              setReferenceNumber={handleReferenceNumberUpdate}
              setBoxLocation={setBoxLocation}
              lookupResponse={{
                woolLotRecordNumber: typingData?.typingProgramData?.woolLotReferenceNumber,
                coreTestId: typingData?.typingProgramData?.coreTestId,
                isNotFound:
                  !typingDataLoadingOrFetching && !!typingData && !typingData?.typingProgramData,
                isLoading: typingDataLoadingOrFetching,
                isSuccess: !typingDataLoadingOrFetching && !!typingData?.typingProgramData,
              }}
              setNoResultsFromScan={setNoResultsFromScan}
              boxLocationValue={typingProgramDisplayData?.coreTestBoxLocation}
              isVerticalLayout
            />
          </Flex>
          <TypingProgramForm
            referenceNumberFromParams={referenceNumberFromParams}
            noResultsFromScan={noResultsFromScan}
            loadingState={typingDataLoadingOrFetching}
            updateActions={mutateActions}
            updateComment={mutateComment}
            updateType={mutateType}
            updating={{
              actions: actionsUpdate.isPending,
              comment: commentUpdate.isPending,
              type: typeUpdate.isPending,
            }}
            initialTypingValues={initialTypingValues}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xl: isWideContainer ? 9 : 8 }}>
          <Stack gap="xl">
            <TypingProgramDetails data={typingProgramDisplayData} isLoading={typingDataLoading} />
            {showTables && (
              <ContractsTable
                isLoading={contractsDataLoadingOrFetching}
                data={contractsData?.contractsData ?? []}
              />
            )}
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12 }}>
          {showTables && (
            <PreviousLotsTable
              heading={previousLotsHeading}
              isLoading={previousLotsDataLoadingOrFetching}
              data={typingPreviousLotsDisplayData() ?? []}
            />
          )}
        </Grid.Col>
      </Grid>
    </PageContainer>
  )
}
