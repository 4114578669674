import { Pill, Group } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'

type IconPillProps = {
  text: string
}

export function PillAlert({ text }: IconPillProps) {
  return (
    <Pill bg="red.2" fw={600} aria-label={`Alert regarding ${text}`}>
      <Group wrap="nowrap" gap="xxs">
        <IconAlertTriangle size={14} stroke={2} />
        {text}
      </Group>
    </Pill>
  )
}
