const pageTitleSuffix = '| New Zealand Merino'

const addTitleSuffix = (title: string) => `${title} ${pageTitleSuffix}`

export const PAGE_TITLES = {
  HOME: addTitleSuffix('Home'),
  TYPING_PROGRAM: addTitleSuffix('Typing Program'),
  TYPED_LOTS: addTitleSuffix('Typed Lots'),
  WOOL_STORE_TOOLS: addTitleSuffix('Wool Store Tools'),
}
