import { MRT_DensityState } from 'mantine-react-table'
import classes from '@/components/shared/table/table.module.css'

/**
 * Default properties for uses of Mantine React Table
 * within the Hub.
 */
export const hubTableDefaultProps = {
  enableSorting: false,
  enableRowSelection: false,
  enableColumnOrdering: false,
  enableGlobalFilter: false,
  enableHiding: false,
  enableFilters: false,
  enableColumnActions: false,
  initialState: { density: 'xs' as MRT_DensityState },
  mantineTableProps: {
    withColumnBorders: true,
    withRowBorders: true,
    withTableBorder: false,
    highlightOnHover: true,
    className: classes.table,
  },
  mantineTableBodyCellProps: {
    className: classes.bodyCell,
  },
  mantineTopToolbarProps: {
    className: classes.topToolbar,
  },
  defaultColumn: {
    size: 50,
    minSize: 20,
    maxSize: Number.MAX_SAFE_INTEGER,
  },
  mantinePaperProps: {
    shadow: 'none',
  },
}
