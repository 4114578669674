import { gql } from 'graphql-request'

export const SetTypingTypeCurrentGql = gql`
  mutation setTypingTypeCurrent($woolLotId: UUID!, $typeCurrent: String) {
    setTypingTypeCurrent(request: { woolLotId: $woolLotId, typeCurrent: $typeCurrent }) {
      errorMessage
      success
    }
  }
`
