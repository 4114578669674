import { List, NavLink as MantineNavLink } from '@mantine/core'
import { NavLink } from 'react-router-dom'
import { ReactNode } from 'react'
import classes from './main-menu.module.css'

export interface MainMenuProps {
  links: Array<TopLevelLinkProps>
}

export interface MainMenuLinkProps {
  label: string
  href: string
}

export interface TopLevelLinkProps extends MainMenuLinkProps {
  icon: ReactNode
  subMenuLinks?: Array<MainMenuLinkProps>
}

export const MainMenu = ({ links }: MainMenuProps) => (
  <List
    spacing="xs"
    listStyleType="none"
    w="100%"
    classNames={{ itemWrapper: classes.itemWrapper, itemLabel: classes.itemLabel }}
    aria-label="Menu items"
  >
    {links.map(({ label, href, icon, subMenuLinks }) => (
      <List.Item key={label}>
        {!!subMenuLinks && (
          <MantineNavLink
            classNames={{
              root: classes.navLink,
              section: classes.iconSection,
              children: classes.subMenu,
              chevron: classes.chevron,
            }}
            label={label}
            to={href}
            leftSection={icon}
            component={NavLink}
            childrenOffset={36}
            defaultOpened
          >
            {subMenuLinks.map((subMenuLink) => (
              <MantineNavLink
                key={subMenuLink.label}
                classNames={{
                  root: classes.navLink,
                  section: classes.iconSection,
                }}
                label={subMenuLink.label}
                to={subMenuLink.href}
                component={NavLink}
              />
            ))}
          </MantineNavLink>
        )}
        {!subMenuLinks && (
          <MantineNavLink
            classNames={{
              root: classes.navLink,
              section: classes.iconSection,
            }}
            label={label}
            to={href}
            leftSection={icon}
            component={NavLink}
          />
        )}
      </List.Item>
    ))}
  </List>
)
