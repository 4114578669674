import { notifications } from '@mantine/notifications'
import { rem } from '@mantine/core'
import { IconX } from '@tabler/icons-react'

export const showErrorNotification = (message: string) => {
  notifications.show({
    message,
    icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
    color: 'red',
    autoClose: false,
    position: 'top-right',
  })
}
