import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { HomePage } from '@/pages/home.page'
import { TypingProgram } from '@/pages/typing/program/typing-program.page'
import { TypedLotsPage } from '@/pages/typing/typed-lots/typed-lots.page'
import { WoolStoreToolsPage } from './pages/wool-store-tools.page'
import { ProtectedRoute } from '@/components/protected-route'
import { AppShell } from '@/components/shared/app-shell/app-shell'

const router = createBrowserRouter([
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <AppShell />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },
          {
            path: '/typing/program',
            element: <TypingProgram />,
          },
          {
            path: '/typing/typed-lots',
            element: <TypedLotsPage />,
          },
          {
            path: '/wool-store-tools',
            element: <WoolStoreToolsPage />,
          },
        ],
      },
    ],
  },
])

export function Router() {
  return <RouterProvider router={router} />
}
